import './App.css';
import Landing from './features/landing/landing';

function App() {
  return (
    <Landing />
  );
}

export default App;
